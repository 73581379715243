import React from "react";
import Image from "@/components/Image";

export default function Chooseus() {
  return (
    <section className="sec-d1 d-block choose-s">
      <div className="container">
        <h1 className="text-center">
          <span className="d-block">
            <img src="images/heading-t.png" alt="picv" />
          </span>
          <span> WHY CHOOSE US </span> The Best Health Care Solution{" "}
        </h1>
        <div className="row g-lg-5 row-cols-1 row-cols-lg-3 mt-3">
          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="private-nursing-icon.png" />
              </figure>
              <h5>
                Qualified Nurses
                <span className="d-block"> Available 24/7</span>{" "}
              </h5>
            </div>
          </div>

          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="certified-services-icon.png" />
              </figure>
              <h5>
                <span className="d-block">
                  {" "}
                  Trusted and Certified
                  <span className="d-block"> Services </span>{" "}
                </span>{" "}
              </h5>
            </div>
          </div>

          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="healthcare-c.png" />
              </figure>
              <h5>
                {" "}
                Provides Essential 
                <span className="d-block"> Assistance </span>{" "}
              </h5>
            </div>
          </div>

          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="trained-staff-icon.png" />
              </figure>
              <h5>
                Trained Staff
                <span className="d-block"> With Proven Experience</span>{" "}
              </h5>
            </div>
          </div>

          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="best-care-icon.png" />
              </figure>
              <h5>
                {" "}
                Best Care
                <span className="d-block"> Possible</span>{" "}
              </h5>
            </div>
          </div>

          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="support-respite-icon.png" />
              </figure>
              <h5>
                Respite and Support
                <span className="d-block"> For Your Life </span>{" "}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
