import React from "react";
// import Image from "@/components/Image";
import { Link } from "gatsby";
import Image from "@/components/Image";

const ApplyWithUs = () => {
  return (
    <section className="sec-new-add" style={{marginTop: '5rem',marginBottom: '2rem'}}>
      <div className="sec-overlay1"></div>
      <div className="sec-overlay2"></div>
      <div className="container mr-lg-0">
        <div className="row row-cols-1 row-cols-lg-2">
          <div className="col d-flex">
            <div className="booking-an-div">
              <h1> Apply with us </h1>
              <p>
                {" "}
                We have all you need in one place and we are sure you have the
                skill
              </p>

              <div className="row row-cols-1 row-cols-lg-2">
                <div className="col-lg-12">
                  <Link to="/contact" className="btn ap-bn">
                    Apply with us <i className="bi bi-arrow-right-short"></i>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <figure>
              <Image name="apply-with-us.png" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplyWithUs;
