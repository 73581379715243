import React, { FC } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

const CrData = [
  {
    imageSrc: '/images/headerlogo-medicaid.png',
    url: 'https://www.medicaid.gov/',
    styles: {
      width: '100%',
      height: '100%',
    },
  },
  {
    imageSrc: '/images/apdheaderlogo.png',
    url: 'https://apd.myflorida.com/',
    styles: {
      width: '100%',
      height: '100%',
    },
  },
  {
    imageSrc: '/images/AHCA_Official_Logo.png',
    url: 'https://ahca.myflorida.com/',
    styles: {
      width: '100%',
      height: '100%',
    },
  },
];

const swiperBreakpoints = {
  595: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
};

const Cred: FC<CredentialsProps> = ({ imageSrc, url, styles }) => (
  <>
    <div className="client-logo-display-single-item swiper-slide d-flex flex-row justify-content-center">
      <div className="image">
        <a href={url} target="_blank">
          <img
            className="img-fluid"
            src={imageSrc}
            alt="Partner"
            style={styles}
          />
        </a>
      </div>
    </div>
  </>
);

const Credentials: FC = () => (
  <div style={{ marginTop: '3rem', marginBottom: '2rem' }}>
    <h1 className="text-center">
      <span className="d-block">
        <img src="images/heading-t.png" alt="picv" />
      </span>
      <span className="text-green"> Credentials </span>{' '}
    </h1>
    <div className="client-logo-display-wrapper " style={{ marginTop: '4rem' }}>
      <div className="client-logo-slider border-bottom-thick-no">
        <Swiper
          spaceBetween={30}
          loop={true}
          className="partners-swiper"
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          breakpoints={swiperBreakpoints}
          modules={[Autoplay]}
        >
          {CrData.map((data, idx) => (
            <SwiperSlide key={`slide-${idx}`}>
              <Cred
                imageSrc={data.imageSrc}
                url={data.url}
                styles={data.styles}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  </div>
);

type CredentialsProps = {
  imageSrc: string;
  url: string;
  styles: React.CSSProperties;
};

export default Credentials;
