import React from "react";
import Image from "@/components/Image";
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';


export default function Cards() {
  return (
    <div className="medi-services">
      <div className="container">
        <div className="row row-cols-1 row-cols-lg-3">
          <div className="col">
            <div className="comon-quick">
              <div className="d-lg-flex align-items-center">
                <figure>
                  <Image name="logo-footer.png" />{" "}
                </figure>
                <h4>Contact us</h4>
              </div>
              <p>Please feel free to contact our friendly reception staff.</p>
              {/* <h5>
                <a
                  href={"tel:" + "+13053968973"}
                 
                >
                  <span>
                    {" "}
                    <i className="bi bi-telephone-forward"></i>{" "}
                  </span>
                  +1 (305) 396-8973
                </a>
              </h5> */}
                <OutboundLink href={"tel:" + "+13053968973"} className="btn btn-light text-card">
                {" "}
                <i className="bi bi-telephone-forward"></i> +1 (305) 396-8973{" "}
              </OutboundLink>
            </div>
          </div>
          <div className="col">
            <div className="comon-quick">
              <div className="d-lg-flex align-items-center">
                <figure>
                  <Image name="logo-footer.png" />{" "}
                </figure>
                <h4>Professional services</h4>
              </div>
              <p>
                Meet our services.
              </p>
              <Link to='/services' className="btn btn-light text-card">
                {" "}
                <i className="bi bi-eye"></i> Services{" "}
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="comon-quick">
              <div className="d-lg-flex align-items-center">
                <figure>
                  <Image name="logo-footer.png" />{" "}
                </figure>
                <h4>Opening Hours</h4>
              </div>
              <ul>
                <li>
                  {" "}
                  <span> Monday – Friday </span> 9.00 AM – 5.00 PM{" "}
                </li>
                {/* <li>
                  {" "}
                  <span> Saturday </span> 9.00 – 8.00 pm{" "}
                </li>
                <li>
                  {" "}
                  
                </li>
                <li>
                  {" "}
                  <span> Emergency</span> 24HR / 7Days{" "}
                </li> */}
              </ul>

              <OutboundLink href={"tel:" + "+13053968973"} className="btn btn-light text-card">
                {" "}
                <i className="bi bi-telephone-forward"></i> +1 (305) 396-8973{" "}
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
