import React from "react";
import "./styles.css";
import Hero from "@/components/Home/Hero";
import Cards from "@/components/Home/Cards";
import Chooseus from "@/components/Home/Chooseus";
import Services from "@/components/Home/Services";
import Stories from "@/components/Home/Stories";
import GetInTouch from "@/components/Home/GetInTouch";
import Subscription from "@/components/Home/Subscription";
import { Partners } from "@/components/Home/Partners";
import Credentials from "@/components/Home/Credentials";
import ApplyWithUs from "@/components/Home/ApplyWithUs";

const Home = () => {
  return (
    <>
      <Hero />

      <Cards />

      <Chooseus />

      <Credentials />

      <ApplyWithUs />

      <Services />

      <Partners />

      <Stories />

      <GetInTouch />

      <Subscription />
    </>
  );
};

export default Home;
