import React from "react";
import { Carousel, CarouselItem } from "react-bootstrap";
import Image from "@/components/Image";
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';


const Hero = () => {
  return (
    <div className="banner-part d-grid align-content-center">
      <Carousel
        prevIcon={<i className="bi bi-chevron-left"></i>}
        nextIcon={<i className="bi bi-chevron-right"></i>}
        className="carousel slide"
        indicators
        fade
        interval={6000}
      >
        <CarouselItem className="carousel-item active">
          <Image name="banner-pic.png" />
          <div className="carousel-caption">
            <div className="container">
              <h1>
                {" "}
                <span> we provide </span>
                <span className="d-block">
                  {" "}
                  High  <b>quality care services</b>{" "}
                </span>
                <span className="d-block"> for you and your loved ones </span>
              </h1>
              <p>We offer a wide range of services</p>
              <div className="d-flex">
                <Link to="/services" className="btn book-bn-comon btn btn-info">
                  {" "}
                  <i className="bi bi-eye"></i> Our services{" "}
                </Link>
                <OutboundLink
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  href={"tel:" + "+13053968973"}
                  className="btn book-bn-comon btn btn-light"
                >
                  {" "}
                  <i className="bi bi-telephone-forward"></i> Call: +1 (305) 396-8973{" "}
                </OutboundLink>
              </div>
            </div>
          </div>
        </CarouselItem>
        <CarouselItem className="carousel-item">
          <Image name="banner-pic2.png" />
          <div className="carousel-caption">
            <div className="container">
              <h1>
                {" "}
                <span> We have </span>
                <span className="d-block">
                  {" "}
                  a
                  <b> qualified team </b>{" "}
                </span>
                <span className="d-block"> prepared to meet all your needs </span>
              </h1>
              <p>We offer a wide range of services</p>
              <div className="d-flex">
                <Link to="/services" className="btn book-bn-comon btn btn-info">
                  {" "}
                  <i className="bi bi-eye"></i> Our services{" "}
                </Link>
                <OutboundLink
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  href={"tel:" + "+13053968973"}
                  className="btn book-bn-comon btn btn-light"
                >
                  {" "}
                  <i className="bi bi-telephone-forward"></i> Call: +1 (305) 396-8973{" "}
                </OutboundLink>
              </div>
            </div>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  );
};

export default Hero;
