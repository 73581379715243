import React from "react";
import Layout from "@/components/layout";
import Header from "@/components/Header";
import Hom from "@/components/Home";
import Footer from "@/components/Footer";
import SEO from "@/components/seo";
import { useLocation } from '@reach/router';

const Home = () => {
  const {host} = useLocation();
  const canonical = `${host}`;
  return (
    <Layout>
      <SEO title="Home" canonical={canonical}/>
      <Header />
      <Hom />
      <Footer />
    </Layout>
  );
};

export default Home;
