import React, { FC } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

const partnerData = [
  {
    imageSrc: `/images/agape.webp`,
    url: `https://rpadvancedsupport.com/`,
    styles: {
      width: `100%`,
      height: `100%`,
    },
  },
  {
    imageSrc: `/images/nimbus-115x115.webp`,
    url: `https://nimbuspc.com/`,
    styles: {
      width: `100%`,
      height: `100%`,
    },
  },
  {
    imageSrc: `/images/integrated-home-care.png`,
    url: `https://ihcscorp.com/`,
    styles: {
      width: `100%`,
      height: `100%`,
    },
  },
  {
    imageSrc: `/images/logo-success-at-work.png`,
    url: `http://successatworkfl.org/`,
    styles: {
      width: `75%`,
      height: `75%`,
    },
  },
  {
    imageSrc: `/images/agape-logo.png`,
    url: `https://www.agapecci.com`,
    styles: {
      width: `75%`,
      height: `75%`,
    },
  },
  {
    imageSrc: `/images/humana.svg`,
    url: `https://www.humana.com/member/using-a-health-insurance-plan/find-in-network-care`,
    styles: {
      width: `75%`,
      height: `75%`,
    },
  },
  {
    imageSrc: `/images/florida-community-care.png`,
    url: `https://fcchealthplan.com/`,
    styles: {
      width: `75%`,
      height: `75%`,
    },
  },
  {
    imageSrc: `/images/molina-healthcare.png`,
    url: `https://www.molinahealthcare.com/`,
    styles: {
      width: `100%`,
      height: `100%`,
    },
  },
  {
    imageSrc: `/images/aetna.svg`,
    url: `https://www.aetna.com/individuals-families.html`,
    styles: {
      width: `75%`,
      height: `75%`,
    },
  },
  {
    imageSrc: '/images/partner-united-health-care.png',
    url: 'https://www.uhc.com/',
    styles: {
      width: '100%',
      height: '100%',
    },
  },
];

const swiperBreakpoints = {
  595: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 50,
  },
};

const Partner: FC<PartnerProps> = ({ imageSrc, url, styles }) => (
  <div className="client-logo-display-single-item swiper-slide d-flex flex-row justify-content-center">
    <div className="image">
      <a href={url} target="_blank" rel="noreferrer">
        <img
          className="img-fluid"
          src={imageSrc}
          alt="Partner"
          style={styles}
        />
      </a>
    </div>
  </div>
);

export const Partners: FC = () => (
  <div>
    <h1 className="text-center">
      <span className="d-block">
        <img src="images/heading-t.png" alt="picv" />
      </span>
      <span className="text-green"> Partners </span>
      {` `}
    </h1>
    <div className="client-logo-display-wrapper " style={{ marginTop: `4rem` }}>
      <div className="client-logo-slider border-bottom-thick-no">
        <Swiper
          spaceBetween={30}
          loop
          className="partners-swiper"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={swiperBreakpoints}
          modules={[Autoplay]}
        >
          {partnerData.map((data, idx) => (
            <SwiperSlide key={`slide-${idx}`} style={{ height: `auto` }}>
              <Partner
                imageSrc={data.imageSrc}
                url={data.url}
                styles={data.styles}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  </div>
);

type PartnerProps = {
  imageSrc: string;
  url: string;
  styles: React.CSSProperties;
};
