import React from "react";
import Image from "@/components/Image";
import { Link } from "gatsby";

const Services = () => {
  return (
    <section className="sec-d4 ">
      <div className="container">
        <h1 className="text-center">
          <span className="d-block">
            <img src="images/heading-t.png" alt="picv" />
          </span>
          <span className="text-black-50"> Our Services </span>
          Providing Health  Care{" "}
        </h1>

        <div className="slider-combo mt-5">
          <div className="ps-sild services-grid">
            <div className="item">
              <div className="card h-100">
                <figure>
                  <img
                    src="images/personal-care-icon.png"
                    className="card-img-top"
                    alt="..."
                  />
                </figure>
                <div className="card-body ">
                  <h5 className="card-title">Personal Care</h5>
                  <p>
                    {" "}
                    Providing assistance and training for recipients under 21.
                    {" "}
                  </p>
                  {/* <ul>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Eating{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Bathing{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Dressing{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Personal hygiene{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Preparation of meals{" "}
                    </li>
                  </ul> */}
                  <>
                    <Link
                      to={"/services"}
                      state={{ showActiveTab: "personalCare" }}
                    >
                      {" "}
                      Read more{" "}
                    </Link>
                  </>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="card h-100">
                <figure>
                  <img
                    src="images/personal-support-icon.png"
                    className="card-img-top"
                    alt="..."
                  />
                </figure>
                <div className="card-body">
                  <h5 className="card-title">Personal Support </h5>
                  <p>
                    {" "}
                    Providing assistance and training for recipients over 21.
                    {" "}
                  </p>
                  {/* <ul>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Eating{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Bathing{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Dressing{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Personal hygiene{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Preparation of meals{" "}
                    </li>
                  </ul> */}
                  <>
                    <Link
                      to={"/services"}
                      state={{ showActiveTab: "personalSupport" }}
                    >
                      {" "}
                      Read more{" "}
                    </Link>
                  </>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="card h-100">
                <figure>
                  <img
                    src="images/homemaker-icon.png"
                    className="card-img-top"
                    alt="..."
                  />
                </figure>
                <div className="card-body">
                  <h5 className="card-title">Homemaker </h5>
                  <p>
                    {" "}
                    Provides assistance to perform one or more of the
                    ADLs.{" "}
                  </p>
                  {/* <ul>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Housekeeping{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Including but not limited to light cleaning and repairs{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Meal preparation{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Assisting during shopping and groceries{" "}
                    </li>
                  </ul> */}
                  <>
                    <Link
                      to={"/services"}
                      state={{ showActiveTab: "homemaker" }}
                    >
                      {" "}
                      Read more{" "}
                    </Link>
                  </>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="card h-100">
                <figure>
                  <img
                    src="images/respite-icon.png"
                    className="card-img-top"
                    alt="..."
                  />
                </figure>
                <div className="card-body">
                  <h5 className="card-title">Respite </h5>
                  <p>
                    {" "}
                    Provides supportive care and supervision to recipients under
                    21.{" "}
                  </p>
                  {/* <ul>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Bathe{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Dress{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Eat or drink{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Take medications on schedule{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        <i className="bi bi-check2-circle"></i>{" "}
                      </span>{" "}
                      Get in and out of bed{" "}
                    </li>
                  </ul> */}
                  <>
                    <Link to={"/services"} state={{ showActiveTab: "respite" }}>
                      {" "}
                      Read more{" "}
                    </Link>
                  </>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="card h-100">
                <figure>
                  <img
                    src="images/life-skill-icon.png"
                    className="card-img-top"
                    alt="..."
                  />
                </figure>
                <div className="card-body">
                  <h5 className="card-title">Life Skill </h5>
                  <p>
                    {" "}
                    Provides essential assistance to the elderly and disabled
                    adults ...{" "}
                  </p>
                  <>
                    <Link
                      to={"/services"}
                      state={{ showActiveTab: "lifeSkill" }}
                    >
                      {" "}
                      Read more{" "}
                    </Link>
                  </>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="card h-100">
                <figure>
                  <img
                    src="images/private-nursing-icon.png"
                    className="card-img-top"
                    alt="..."
                  />
                </figure>
                <div className="card-body">
                  <h5 className="card-title">Private Duty Nursing </h5>
                  <p>
                    {" "}
                    Provides 24hr 1 to 1 continuous care...{" "}
                  </p>
                  <Link
                    to={"/services"}
                    state={{ showActiveTab: "privateDutyNursing" }}
                  >
                    {" "}
                    Read more{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card h-100">
                <figure>
                  <img
                    src="images/residential-nursing-icon.png"
                    className="card-img-top"
                    alt="..."
                  />
                </figure>
                <div className="card-body">
                  <h5 className="card-title">Residential nursing </h5>
                  <p>
                    {" "}
                    Provides 24hr 1 to 1 continuous care ...{" "}
                  </p>
                  <>
                    <Link
                      to={"/services"}
                      state={{ showActiveTab: "residentialNursing" }}
                    >
                      {" "}
                      Read more{" "}
                    </Link>
                  </>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card h-100">
                <figure>
                  <img
                    src="images/skilled-nursing-icon.png"
                    className="card-img-top"
                    alt="..."
                  />
                </figure>
                <div className="card-body">
                  <h5 className="card-title">Skilled nursing </h5>
                  <p>
                    {" "}
                    Providing 24hr 1 to 1 continuous care...{" "}
                  </p>
                  <>
                    <Link
                      to={"/services"}
                      state={{ showActiveTab: "skilledNursing" }}
                    >
                      {" "}
                      Read more{" "}
                    </Link>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
