import React from "react";
import { Carousel, CarouselItem } from "react-bootstrap";
import Image from "@/components/Image";

const Stories = () => {
  return (
    <section className="testimonal-sec py-5">
      <div className="container-fluid">
        <div className="row row-cols-1 row-cols-lg-2">
          <div className="col p-0 year-text text-center">
            <figure className="left-p">
              {/* <img src="images/ptc.jpg" alt="pic" /> */}
              <Image name="ptc.jpg" classes="storie-img" />
            </figure>
          </div>
          <div className="col">
            <div className="silder-div-text">
              <h1> Inspiring Stories! </h1>
              <div className="best-sl-m owl-carousel owl-theme">
                <Carousel
                  className="w-100 overflow-hidden"
                  slide
                  touch
                  controls={false}
                  interval={6000}
                  prevIcon={""}
                  nextIcon={""}
                >
                  <CarouselItem className="item">
                    <div className="comon-div-ts">
                      <h5>
                        {" "}
                        Our family has been using Start Professional and Care
                        for the past 2 years to provide private duty nursing to
                        our grandfather. We’d like to thank the staff for
                        been kind, professional, and considerate.
                      </h5>
                      <div className="d-flex mt-4 align-items-center">
                        <figure>
                          <Image name="testimonials-1-1.jpg" />
                          {/* <img src="images/testimonials-1-1.jpg" alt="pic" /> */}
                        </figure>
                        <h4>
                          {" "}
                          John Davis
                        </h4>
                      </div>
                    </div>
                  </CarouselItem>

                  <CarouselItem className="item">
                    <div className="comon-div-ts">
                      <h5>
                        {" "}
                        My experience with Like Skill service has been
                        excellent. They have been helping me in a very difficult
                        time. I feel accompanied and happy.{" "}
                      </h5>
                      <div className="d-flex mt-4 align-items-center">
                        <figure>
                          <Image name="testimonials-1-2.jpg" />

                          {/* <img src="images/testimonials-1-2.jpg" alt="pic" /> */}
                        </figure>
                        <h4>
                          {" "}
                          Nancy Roberts
                        </h4>
                      </div>
                    </div>
                  </CarouselItem>

                  <CarouselItem className="item">
                    <div className="comon-div-ts">
                      <h5>
                        {" "}
                        Personal Care is a great service. I find the caregivers
                        they provide to be very trustworthy, experienced,
                        dedicated, and caring.{" "}
                      </h5>
                      <div className="d-flex mt-4 align-items-center">
                        <figure>
                          <Image name="testimonials-1-3.jpg" />

                          {/* <img src="images/testimonials-1-1.jpg" alt="pic" /> */}
                        </figure>
                        <h4>
                          {" "}
                          Laura Miller
                        </h4>
                      </div>
                    </div>
                  </CarouselItem>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stories;
